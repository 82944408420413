.todo-header-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 0;
}

.todo-header-text {
    flex: 1;
}

.action-buttons {
    display: flex;
    column-gap: 7px;
}
