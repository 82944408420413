@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400; /* Normal weight */
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold weight */
    font-style: normal;
}
