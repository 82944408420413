@import 'typography.css';

* {
  font-family: Montserrat, sans-serif;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*body {*/
/*  background: #121212;*/
/*  font-family: 'Nunito', sans-serif;*/
/*  color: #fff;*/
/*}*/

.container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}

input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
}

input:focus {
  border: 1px solid #74b4ff;
}

/* -- scrollbar -- */

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9e8e8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9a8a8;;
}
