.completed {
    color: #ccc;
    text-decoration-line: line-through;
}

.dragged {
    border-bottom: 3px solid grey;
}

.line-separator {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.header-line {
    border-bottom: 1px solid #ccc;
}

.todo-text {
    flex: 1;
    cursor: pointer;
}

.todo-wrapper {
    display: flex;
    flex-direction: column;
}

.todo-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.remove-task-button {
    height: 40px;
    background: none;
    border: 1px solid orangered;
    border-radius: 10px;
}

.remove-task-button:hover {
    cursor: pointer;
    background: orangered;
    color: white;
}

.todo-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: default; /* Avoid showing a pointer cursor on the whole item */
}

.drag-handle {
    cursor: grab;
    padding: 5px;
}

.drag-handle:active {
    cursor: grabbing;
}

.edit-icon {
    color: #00bfff;
    padding: 0 5px;
    /*font-size: 20px;*/
}

.delete-icon {
    color: #fb2e2e;
    padding: 0 5px;
    font-size: 20px;
}

.subtasks {
    padding-left: 35px;
    display: flex;
    flex-direction: column;
}

.new-subtask {
    margin-left: 35px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 0;
}
