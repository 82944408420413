.navigation-menu-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
}

.navigation-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    padding: 20px;
    background-color: deepskyblue;
    color: white;
}

.navigation-menu-item {
    display: flex;
    justify-content: center;
    flex: 1;
    font-size: 24px;
    margin-left: 28px;
}

.menu-icon {
    font-size: 23px;
}
