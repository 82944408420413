.button-container {
    min-width: 48px;
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.button-container.ghost {
    background: none;
    color: black;
}

.primary,
.primary:hover {
    background: #00bfff;
    color: white;
    border: 1px solid #00bfff;
}

.secondary,
.secondary:hover {
    background: #ccc;
    color: white;
    border: 1px solid #ccc;
}

.success,
.success:hover {
    background: #15bd15;
    color: white;
    border: 1px solid #15bd15;
}

.danger,
.danger:hover {
    background: #fb2e2e;
    color: white;
    border: 1px solid #fb2e2e;
}

.warning,
.warning:hover {
    background: orange;
    color: white;
    border: 1px solid orange;
}

.info,
.info:hover {
    background: cadetblue;
    color: white;
    border: 1px solid cadetblue;
}


