.list-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background-color: #f7f7f7;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 1000;
}

.close-menu-icon {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.list-menu-container {
    flex-grow: 1;
    overflow-y: auto;
}

.list-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.list-menu-item:hover {
    background-color: #e9e9e9;
}

.list-menu-item input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.list-menu-item input:focus {
    outline: none;
    box-sizing: border-box;
    border: 2px solid deepskyblue;
}

.list-menu-item .list-name {
    padding: 10px 0;
    width: 100%;
}

.list-menu-item.add-list {
    padding: 10px;
}

.list-menu-item button {
    background-color: deepskyblue;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.list-menu-item button:hover {
    background-color: #0286ea;
}

.selected {
    background-color: #ffffff;
}
