.subtask-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    cursor: default;
    column-gap: 10px;
}

.subtask-buttons-container {
    display: flex;
    column-gap: 10px;
}


.subtask-text {
    flex: 1;
    cursor: pointer;
}
